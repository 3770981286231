import {PlusCircleOutlined} from '@ant-design/icons';
import Auth from 'Auth';
import {Alert, Button, Collapse, Divider, Drawer, Spin, message} from 'antd';
import {
  useDeleteReportMutation,
  useFetchAggregateReportsListQuery,
  useFetchReportMetaQuery,
  useFetchReportSourceColumnsQuery,
  useFetchReportSourcesQuery,
  usePublishReportMutation,
  useSaveReportMutation,
} from 'api/reportsSlice';
import {
  useCreateOrUpdateCategoryMutation,
  useCreateOrUpdateTagMutation,
  useFetchCategoriesQuery,
  useFetchTagsQuery,
} from 'api/tagsSlice';
import ColumnForm from 'components/dataReports/ColumnForm';
import EditorButton from 'components/genericComponents/EditorButton';
import InputField from 'components/genericComponents/Input';
import PublishButton from 'components/genericComponents/PublishButton';
import SelectOptions from 'components/genericComponents/SelectOptions';
import VersionItem from 'components/genericComponents/VersionItem';
import React, {useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {selectFormState, setFormData, updateFormField} from 'store/formSlice';
import styled from 'styled-components';
import {generateUniqueId} from 'utils/helpers';

const {Panel} = Collapse;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

const DataReportForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reportSlug = location.pathname.split('/')[2];

  const params = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const formState = useSelector((state) =>
    selectFormState(state, `data_report_${reportSlug}`)
  );

  const editorMode =
    (location.pathname.endsWith('/editor') ||
      location.pathname.startsWith('/data_report_editor')) &&
    Auth.permissions.access_to_data_reports_editor;

  const [allErrors, setAllErrors] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(reportSlug === 'new');
  const [localErrors, setLocalErrors] = useState([]);

  const {data: reports} = useFetchAggregateReportsListQuery(false);
  const {data: reportData, isLoading} = useFetchReportMetaQuery(
    {
      slug: reportSlug,
      version: params.get('version') || 'latest',
    },
    {
      skip: !reportSlug || reportSlug === 'new',
    }
  );
  const {data: sources} = useFetchReportSourcesQuery();
  const {data: columns, isLoading: loadingColumns} =
    useFetchReportSourceColumnsQuery(formState?.source, {
      skip: !formState?.source,
    });
  const {data: categories} = useFetchCategoriesQuery();
  const {data: tags} = useFetchTagsQuery();

  const [createOrUpdateCategory] = useCreateOrUpdateCategoryMutation();
  const [createTag] = useCreateOrUpdateTagMutation();
  const [deleteReport] = useDeleteReportMutation();
  const [publishReport] = usePublishReportMutation();
  const [saveReport] = useSaveReportMutation();

  useEffect(() => {
    if (!editorMode) {
      setDrawerVisible(false);
    }
  }, [editorMode]);

  useEffect(() => {
    dispatch(
      setFormData({
        id: `data_report_${reportSlug}`,
        data: reportData || {},
      })
    );
  }, [reportData]);

  useEffect(() => {
    if (!formState) return;
    const errors = [];

    if (formState?.columns) {
      formState.columns.forEach((column) => {
        if (!column.alias) {
          errors.push('Column alias is required');
        }
        if (column.column_type === 'METRIC') {
          if (!column.metric_type) {
            errors.push('Metric type is required');
          }
        }
        if (!column.source_column) {
          errors.push('Source column is required');
        }
      });
    } else {
      errors.push('Please add at least one column');
    }
    if (!formState?.report_name) {
      errors.push('Report name is required');
    }
    if (!formState?.source) {
      errors.push('Report source is required');
    }
    if (
      reports?.some(
        (r) =>
          r.report_name?.toLowerCase() ===
          formState.report_name?.toLowerCase() &&
          r.slug !== formState.slug
      )
    ) {
      errors.push('A report with this name already exists');
    }
    setLocalErrors(errors);
  }, [formState]);

  useEffect(() => {
    setAllErrors(localErrors.concat(reportData?.errors || []));
  }, [localErrors, reportData]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formState.columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleReportDataChanged(items, 'columns');
  };

  const handleReportDataChanged = (value, type) => {
    dispatch(
      updateFormField({id: `data_report_${reportSlug}`, field: type, value})
    );
    if (type === 'source' && !formState.report_name) {
      dispatch(
        updateFormField({
          id: `data_report_${reportSlug}`,
          field: 'report_name',
          value: value + ' Report',
        })
      );
    }
  };

  const handleAddColumn = (value) => {
    const newId = generateUniqueId(
      formState?.columns.map((column) => column.id)
    );
    handleReportDataChanged(
      [
        ...formState?.columns,
        {
          column_type: value,
          filter_type: 'FREE_TEXT',
          id: newId,
        },
      ],
      'columns'
    );
  };

  const handleColumnChanged = (idx, value) => {
    const newCols = formState.columns.map((col, i) => {
      if (i === idx) {
        return value;
      }
      return col;
    });
    handleReportDataChanged(newCols, 'columns');
  };

  const handleSave = (publish = false) => {
    if (localErrors.length) {
      message.error('Please fix the errors before saving');
    } else {
      const report = {
        ...formState,
        published: publish,
        tags: formState?.tags?.map((t) => t.id),
      };
      if (reportSlug === 'new') {
        dispatch(
          setFormData({id: `data_report_new`, data: {}})
        );
      }
      saveReport(report)
        .unwrap()
        .then((res) => {
          message.success('Report saved successfully');
          navigate(`/data_report/${res.slug}/editor`);
        });
    }
  };

  const handleDeleteColumn = (col) => {
    const temp = [...formState?.columns];
    const idx = temp.findIndex((c) => c.id === col.id);
    temp.splice(idx, 1);
    handleReportDataChanged(temp, 'columns');
  };

  const handleDelete = (version) => {
    message.loading({content: 'Deleting report...', key: 'delete'});
    if (reportSlug) {
      deleteReport({
        slug: reportSlug,
        version,
      })
        .unwrap()
        .then(() => {
          message.success({content: 'Report deleted', key: 'delete'});
        });
    }
    navigate(
      version ? `/data_report/${reportSlug}/editor` : `/data_report/editor`
    );
  };

  const handleAddColumnsFromSource = () => {
    const temp = columns.map((column) => ({
      alias: column.column_name,
      column_type: 'DIMENSION',
      data_type: column.data_type,
      filter_type: 'FREE_TEXT',
      id: generateUniqueId(formState?.columns?.map((c) => c.id)),
      source_column: column.column_name,
    }));
    handleReportDataChanged(temp, 'columns');
  };

  const handlePublish = (publish, version) => {
    if (publish && localErrors.length) {
      message.error('Please fix the errors before publishing');
    } else {
      message.loading({
        content: publish ? 'Publishing report...' : 'Unpublishing report...',
        key: 'publish',
      });
      publishReport({
        slug: reportSlug,
        version,
        published: publish,
      })
        .unwrap()
        .then(() => {
          message.success({
            content: publish
              ? 'Published successfully'
              : 'Unpublished successfully',
            key: 'publish',
          });
        });
    }
  };

  return (
    <>
      <Drawer
        closable={false}
        extra={
          <PublishButton
            formId={`data_report_${reportSlug}`}
            handleDelete={handleDelete}
            handlePublish={handlePublish}
            handleSave={handleSave}
            isLoading={isLoading}
            isValid={
              !allErrors.length &&
              formState?.columns?.length > 0 &&
              formState?.report_name &&
              formState?.source
            }
          />
        }
        getContainer={false}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        placement="left"
        style={{
          boxShadow: '0 9px 28px 8px rgba(0, 0, 0, 0.05)',
          minHeight: 'calc(100% - 64px)',
          overflowY: 'auto',
          position: 'fixed',
        }}
        title={<h3>Data Report Editor</h3>}
        width={600}
      >
        <Spin spinning={isLoading}>
          <div className="flex-column">
            <div className="flex-row" style={{justifyContent: 'space-between'}}>
              <SelectOptions
                allowClear={false}
                label="Report Source"
                options={sources}
                onChange={(value) => {
                  handleReportDataChanged(value, 'source');
                }}
                required={true}
                rules={[{required: true, message: 'Please select a source'}]}
                style={{flexGrow: 1}}
                value={formState?.source}
              />
              {columns?.length && !formState?.columns?.length && (
                <Button onClick={handleAddColumnsFromSource} size="large">
                  Load All Columns
                </Button>
              )}
            </div>
            <InputField
              label="Name"
              onChange={(e) =>
                handleReportDataChanged(e.target.value, 'report_name')
              }
              required={true}
              // rules={[
              //   {required: true, message: 'Please input a name'},
              //   {
              //     max: 100,
              //     message: 'Name must be less than 100 characters',
              //   },
              //   {
              //     pattern: /^[a-zA-Z0-9_\- ]*$/,
              //   },
              //   {
              //     // unique name validation against all reports
              //     validator: (_, value) => {
              //       if (
              //         reports?.reports?.some(
              //           (r) =>
              //             r.report_name.toLowerCase() === value.toLowerCase()
              //         )
              //       ) {
              //         return Promise.reject(
              //           new Error('A report with this name already exists')
              //         );
              //       }
              //       return Promise.resolve();
              //     },
              //   },
              // ]}
              style={{width: '100%'}}
              value={formState?.report_name}
            />
            {allErrors.length ? (
              <Alert
                type="error"
                description={
                  <div>
                    {allErrors.map((error, idx) => (
                      <div key={idx}>* {error}</div>
                    ))}
                  </div>
                }
              />
            ) : null}
            <SelectOptions
              label="Category"
              onAdd={(name) => {
                createOrUpdateCategory({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleReportDataChanged(res, 'category');
                  });
              }}
              onChange={(value) =>
                handleReportDataChanged(
                  {
                    id: value.id,
                    name: value.title,
                    parent: value.parent,
                  },
                  'category'
                )
              }
              options={categories?.children}
              tree={true}
              value={formState?.category?.id}
            />
            <SelectOptions
              label="Tags"
              onAdd={(name) => {
                createTag({
                  name,
                })
                  .unwrap()
                  .then((res) => {
                    handleReportDataChanged(
                      [...(formState?.tags || []), res],
                      'tags'
                    );
                  });
              }}
              onChange={(value) =>
                handleReportDataChanged(
                  value.map((v) => tags?.find((c) => c.id === v) || null),
                  'tags'
                )
              }
              mode="tags"
              multiple
              options={tags?.map((c) => ({
                color: c.color,
                label: c.name,
                value: c.id,
              }))}
              style={{width: '100%'}}
              value={formState?.tags?.map((t) => t.id)}
            />
            <InputField
              label="Description"
              onChange={(e) =>
                handleReportDataChanged(e.target.value, 'short_description')
              }
              value={formState?.short_description}
              multiline={true}
              style={{width: '100%'}}
            />
            <InputField
              label="Detailed Description"
              onChange={(e) =>
                handleReportDataChanged(e.target.value, 'long_description')
              }
              value={formState?.long_description}
              multiline={true}
              style={{width: '100%'}}
            />
          </div>
          <Divider />

          <Alert
            description={
              <ul>
                <li>
                  Drag and drop columns to change their order in the report.
                </li>
                <li>
                  Frozen columns will always be displayed at the left of the
                  table.
                </li>
                <li>
                  The sort priority follows the order of columns in this list,
                  with the first column having the highest priority. Frozen
                  columns' sort priorities are also determined by their position
                  in this list, even though they’re displayed on the left side
                  of the table.
                </li>
                <li>
                  Filters defined in the <b>Filters</b> section below will be
                  external the table.
                </li>
              </ul>
            }
            type="info"
            style={{marginBottom: '20px'}}
          />
          <Spin spinning={loadingColumns}>
            {columns?.length > 0 && (
              <>
                <div className="flex-row">
                  <div
                    style={{
                      flex: '1 1 300px',
                    }}
                  >
                    <h4>Columns</h4>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="1">
                        {(provided) => (
                          <ColumnList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {formState?.columns
                              ?.filter((c) => c.column_type !== 'FILTER')
                              .map((column, idx) => (
                                <ColumnForm
                                  column={column}
                                  columns={columns}
                                  handleDelete={() =>
                                    handleDeleteColumn(column)
                                  }
                                  idx={idx}
                                  key={column?.id}
                                  setColumn={(value) =>
                                    handleColumnChanged(idx, value)
                                  }
                                />
                              ))}
                            {provided.placeholder}
                          </ColumnList>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button
                      onClick={() => handleAddColumn('DIMENSION')}
                      type="primary"
                    >
                      <PlusCircleOutlined />
                      Add Column
                    </Button>
                  </div>
                </div>
                <Divider />
                <div>
                  <div
                    style={{
                      flex: '1 1 300px',
                    }}
                  >
                    <h4>Filters</h4>

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="2">
                        {(provided) => (
                          <ColumnList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {formState?.columns
                              ?.filter((c) => c.column_type === 'FILTER')
                              .map((filter, idx) => (
                                <ColumnForm
                                  column={filter}
                                  columns={columns}
                                  handleDelete={() =>
                                    handleDeleteColumn(filter)
                                  }
                                  idx={idx}
                                  key={filter?.id}
                                  setColumn={(value, field) =>
                                    handleColumnChanged(value, filter, field)
                                  }
                                />
                              ))}
                            {provided.placeholder}
                          </ColumnList>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button
                      onClick={() => handleAddColumn('FILTER')}
                      type="primary"
                    >
                      <PlusCircleOutlined />
                      Add Filter
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Spin>
          <Divider />
          {formState?.version_history?.length > 1 ? (
            <Collapse
              expandIconPosition="end"
              ghost
              style={{marginTop: '20px'}}
            >
              <Panel header="Version History" key="1">
                {reportData?.version_history?.map((version) => {
                  return (
                    <VersionItem
                      currentVersion={reportData.version}
                      handleDelete={() => handleDelete(version.version)}
                      handlePublish={(publish) =>
                        handlePublish(publish, version.version)
                      }
                      key={version.version}
                      version={version}
                    />
                  );
                })}
              </Panel>
            </Collapse>
          ) : null}
        </Spin>
      </Drawer>
      <EditorButton
        drawerVisible={drawerVisible}
        key={reportSlug + editorMode}
        path={`/data_report/${reportSlug}`}
        setDrawerVisible={setDrawerVisible}
      />
    </>
  );
};

export default DataReportForm;
