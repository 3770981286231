import {createSelector, createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  forms: {},
  originalData: {},
};

export const formSlice = createSlice({
  initialState,
  name: 'form',
  reducers: {
    setFormData(state, action) {
      const {id, data} = action.payload;
      state.forms[id] = {...data};
      state.originalData[id] = {...data}; // Keep a copy of the original data
    },
    updateFormField(state, action) {
      const {id, field, value} = action.payload;
      if (state.forms[id]) {
        state.forms[id] = {
          ...state.forms[id],
          [field]: value,
        };
      } else {
        console.error(`Form with id ${id} not found`);
      }
    },
    updateOriginalField(state, action) {
      const {id, field, value} = action.payload;
      if (state.originalData[id]) {
        state.originalData[id] = {
          ...state.originalData[id],
          [field]: value,
        };
      } else {
        console.error(`Form with id ${id} not found`);
      }
    },
    resetForm(state, action) {
      const id = action.payload;
      if (state.originalData[id]) {
        state.forms[id] = {...state.originalData[id]};
      } else {
        console.warn(`Original data for form "${id}" does not exist.`);
      }
    },
  },
});

export const {setFormData, updateFormField, updateOriginalField, resetForm} =
  formSlice.actions;

export const selectFormState = (state, formId) => state.form.forms[formId];

export const selectOriginalFormData = (state, formId) =>
  state.form.originalData[formId];

export const selectIsFormEdited = createSelector(
  [selectFormState, selectOriginalFormData],
  (formState, originalData) => {
    if (!formState || !originalData) return false;
    return !_.isEqual(formState, originalData);
  }
);

// selector for if any version of the form is published
export const selectHasPublishedVersion = createSelector(
  [selectFormState],
  (formState) => {
    if (!formState) return false;
    return (
      Array.isArray(formState.version_history) &&
      formState.version_history.some((version) => version.published)
    );
  }
);

export const selectVersionsCount = createSelector(
  [selectFormState],
  (formState) => {
    if (!formState) return 0;
    return Array.isArray(formState.version_history)
      ? formState.version_history.length
      : 0;
  }
);

export default formSlice.reducer;
