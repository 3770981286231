import {emptySplitApi} from 'api/apiSlice';
import {handleRTKQError} from 'utils/errorHandler';

const cmsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPages: builder.query({
      query: (version) => `/cms/page_tree?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'CmsPages', id: arg}],
    }),
    fetchPage: builder.query({
      query: ({slug, version}) => `/cms/page/${slug}/?version=${version}`,
      transformResponse: (response) => {
        const sortedWidgets =
          response.widgets?.sort((a, b) => a.position - b.position) || [];
        return {
          ...response,
          widgets: sortedWidgets,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'CmsPages', id: `${arg.slug}`},
      ],
    }),
    savePage: builder.mutation({
      query: (page) => ({
        url: `/cms/page/`,
        method: 'POST',
        body: page,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages'],
    }),
    publishPage: builder.mutation({
      query: (page) => ({
        url: `/cms/page/${page.slug}/`,
        method: 'PUT',
        body: page,
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages'],
    }),
    deletePage: builder.mutation({
      query: ({slug, version}) => ({
        url: `/cms/page/${slug}/?version=${version}`,
        method: 'DELETE',
      }),
      transformErrorResponse: handleRTKQError,
      invalidatesTags: ['CmsPages'],
    }),
    fetchSupersetToken: builder.query({
      query: (dashboardId) => `/cms/guest_token?dashboard_id=${dashboardId}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        'SupersetToken',
        {type: 'SupersetToken', id: arg},
      ],
      transformResponse: (response) => response.token,
    }),
  }),
});

export const {
  useFetchPagesQuery,
  useFetchPageQuery,
  useSavePageMutation,
  usePublishPageMutation,
  useDeletePageMutation,
  useFetchSupersetTokenQuery,
} = cmsSlice;
