import {CloseCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Button, Checkbox, Tooltip} from 'antd';
import {
  useFetchReportSourceColumnsQuery,
  useFetchReportSourcesQuery,
} from 'api/reportsSlice';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import Tags from 'components/genericComponents/TagsInput';
import {columnTypes} from 'components/seeds/HandsonSettings';
import useThrottle from 'hooks/Throttle';
import React, {useEffect, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {stringToFloat} from 'utils/helpers';

const ColContainer = styled.div`
  display: flex;
  gap: 10px;
  border: 1px solid #ddd;
  align-items: start;
  padding: 8px;
  background-color: white;
  &:hover {
    border-color: #aaa;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px;
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const LinkedColumn = ({
  column,
  idx,
  parent,
  sourceColumns,
  handleChange,
  handleDelete,
}) => {
  const [name, setName] = useState(column.name);

  const throttled = useThrottle((value, field) => {
    handleChange(value, field);
  }, 1000);

  useEffect(() => {
    throttled(name, 'name');
  }, [name]);

  useEffect(() => {
    if (
      column.source_column &&
      sourceColumns &&
      !sourceColumns.some((col) => col.column_name === column.source_column)
    ) {
      handleChange('', 'source_column');
    }
  }, [column.source_column, sourceColumns]);

  const getOptions = () => {
    if (!sourceColumns) return [];
    return sourceColumns
      .map((col) => ({
        value: col.column_name,
        label: col.column_name,
        disabled:
          (col.column_name === parent.source_column && idx !== 'main') ||
          parent.linked_columns?.some(
            (c, i) => c.source_column === col.column_name && idx !== i
          ),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <InnerContainer>
      {idx !== 'main' && (
        <Tooltip title="Remove Column">
          <CloseCircleOutlined
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(idx);
            }}
            style={{
              margin: '8px 0',
            }}
          />
        </Tooltip>
      )}
      <SelectOptions
        options={getOptions()}
        value={column?.source_column}
        onChange={(value) => handleChange(value, 'source_column')}
        label="Source Column"
        required={true}
        style={{width: '200px'}}
      />
      <InputField
        label="Column Name"
        required={true}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Checkbox
        checked={column.is_read_only}
        onChange={(e) => handleChange(e.target.checked, 'is_read_only')}
        style={{
          fontSize: '10px',
          margin: 0,
          paddingLeft: 0,
        }}
      >
        Read Only
      </Checkbox>
    </InnerContainer>
  );
};

const SeedColumnForm = ({column, setColumn, idx, handleDelete}) => {
  const {data: sources} = useFetchReportSourcesQuery();
  const {data: sourceColumns} = useFetchReportSourceColumnsQuery(
    column.source_table,
    {
      skip: !column.source_table,
    }
  );

  const getStyleByState = (snapshot) => {
    if (snapshot.isDragging) {
      return {
        backgroundColor: '#eee',
      };
    }
    return {};
  };

  const handleColumnChanged = (value, field) => {
    // TODO: display an error message if not unique
    // if (field === 'name') {
    //   const isUnique = !columns.some(
    //     (col) => col.name === value && col.col_id !== column.col_id
    //   );
    //   if (!isUnique) return;
    // }

    const updatedColumn = {
      ...column,
      [field]: value,
    };

    if (field === 'source_column') {
      updatedColumn.data_type = sourceColumns.find(
        (c) => c.column_name === value
      )?.data_type;
    } else if (field === 'type') {
      switch (value) {
        case 'text':
          updatedColumn.data_type = 'STRING';
          break;
        case 'date':
          updatedColumn.data_type = 'DATE';
          break;
        case 'numeric':
          updatedColumn.data_type = 'INTEGER';
          break;
        case 'dropdown':
          updatedColumn.data_type = 'STRING';
          break;
        case 'dropdown_numeric':
          updatedColumn.type = 'dropdown';
          updatedColumn.data_type = 'INTEGER';
          break;
        default:
          break;
      }
    }
    if (
      updatedColumn.data_type === 'INTEGER' &&
      updatedColumn.type === 'dropdown'
    ) {
      // if there are no options, set source to empty array
      if (!updatedColumn.source) {
        updatedColumn.source = [];
      } else if (typeof updatedColumn.source === 'string') {
        // if there are options, convert source options to numbers
        const sourceArray = JSON.parse(
          updatedColumn.source.replace(/'/g, '"') ?? '[]'
        );
        const newSource = sourceArray?.map((s) => stringToFloat(s));
        updatedColumn.source = newSource;
      }
    }
    setColumn(updatedColumn);
  };

  const handleAddLinkedColumn = () => {
    setColumn({
      ...column,
      linked_columns: [
        ...column.linked_columns,
        {
          source_column: '',
          name: '',
          is_read_only: true,
        },
      ],
    });
  };

  return (
    <Draggable
      key={column.col_id}
      draggableId={`column-${column.col_id}`}
      index={idx}
    >
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          ...getStyleByState(snapshot),
        };
        return (
          <ColContainer
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            style={style}
          >
            <Tooltip title="Remove Column">
              <CloseCircleOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(idx);
                }}
                style={{
                  margin: '12px 0',
                }}
              />
            </Tooltip>
            <Container>
              <InnerContainer>
                <SelectOptions
                  value={
                    column.type === 'dropdown' && column.data_type === 'INTEGER'
                      ? 'dropdown_numeric'
                      : column.type
                  }
                  onChange={(value) => handleColumnChanged(value, 'type')}
                  options={columnTypes}
                  style={{width: '150px'}}
                  allowClear={false}
                  label="Column Type"
                  required={true}
                />
                {column.type === 'autocomplete' ? (
                  <SelectOptions
                    options={sources}
                    value={column?.source_table}
                    onChange={(value) =>
                      handleColumnChanged(value, 'source_table')
                    }
                    label="Source Table"
                    required={true}
                    style={{flexGrow: 1, width: '320px'}}
                  />
                ) : (
                  <InputField
                    label="Column Name"
                    required={true}
                    value={column.name}
                    onChange={(e) =>
                      handleColumnChanged(e.target.value, 'name')
                    }
                    style={{width: '250px'}}
                  />
                )}
              </InnerContainer>
              {column.type === 'autocomplete' && (
                <>
                  <LinkedColumn
                    column={column}
                    parent={column}
                    idx={'main'}
                    sourceColumns={sourceColumns}
                    handleChange={handleColumnChanged}
                  />
                  <h5>Linked Columns:</h5>
                  {column.linked_columns?.map((col, idx) => (
                    <LinkedColumn
                      key={col.source_column + col.name}
                      column={col}
                      parent={column}
                      idx={idx}
                      sourceColumns={sourceColumns}
                      handleChange={(value, field) =>
                        handleColumnChanged(
                          [
                            ...column.linked_columns.slice(0, idx),
                            {
                              ...col,
                              [field]: value,
                              data_type: sourceColumns?.find(
                                (c) => c.column_name === col.source_column
                              )?.data_type,
                            },
                            ...column.linked_columns.slice(idx + 1),
                          ],
                          'linked_columns'
                        )
                      }
                      handleDelete={(idx) =>
                        handleColumnChanged(
                          [
                            ...column.linked_columns.slice(0, idx),
                            ...column.linked_columns.slice(idx + 1),
                          ],
                          'linked_columns'
                        )
                      }
                    />
                  ))}
                  <Tooltip title="Linked columns are edited together, allowing to search and select data as a group">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddLinkedColumn();
                      }}
                      size="small"
                      style={{marginLeft: '24px', width: 'min-content'}}
                    >
                      <PlusCircleOutlined />
                      Add
                    </Button>
                  </Tooltip>
                  {/* <SelectOptions
                    options={columns
                      .filter(
                        (col) =>
                          col.type === 'autocomplete' &&
                          col.col_id !== column.col_id &&
                          col.source_table === column.source_table
                      )
                      .map((col) => ({
                        value: col.col_id,
                        label: col.name,
                      }))}
                    value={column?.filtered_by}
                    onChange={(value) =>
                      handleColumnChanged(value, 'filtered_by')
                    }
                    placeholder="Select columns that will filter this"
                    multiple={true}
                    style={{width: '250px', margin: 0}}
                  /> */}
                </>
              )}
              {column.type === 'dropdown' && (
                <InnerContainer>
                  <Tags
                    tags={
                      !column.source
                        ? []
                        : typeof column.source === 'string'
                          ? JSON.parse(
                              column?.source.replace(/'/g, '"') ?? '[]'
                            )
                          : column.source
                    }
                    setTags={(value) => handleColumnChanged(value, 'source')}
                  />
                </InnerContainer>
              )}
              <InnerContainer>
                <Checkbox
                  checked={column.allow_empty}
                  onChange={(e) =>
                    handleColumnChanged(e.target.checked, 'allow_empty')
                  }
                  style={{
                    fontSize: '10px',
                    margin: 0,
                  }}
                >
                  Allow Empty
                </Checkbox>
                <Checkbox
                  checked={column.allow_invalid}
                  onChange={(e) =>
                    handleColumnChanged(e.target.checked, 'allow_invalid')
                  }
                  style={{
                    fontSize: '10px',
                    margin: 0,
                  }}
                >
                  Allow Invalid
                </Checkbox>
                {column.type !== 'autocomplete' && (
                  <Checkbox
                    checked={column.is_read_only}
                    onChange={(e) =>
                      handleColumnChanged(e.target.checked, 'is_read_only')
                    }
                    style={{
                      fontSize: '10px',
                      margin: 0,
                    }}
                  >
                    Read Only
                  </Checkbox>
                )}
                <Checkbox
                  checked={column.is_unique}
                  onChange={(e) =>
                    handleColumnChanged(e.target.checked, 'is_unique')
                  }
                  style={{
                    fontSize: '10px',
                    margin: 0,
                  }}
                >
                  Unique
                </Checkbox>
                {(column.type === 'autocomplete' ||
                  column.type === 'dropdown') && (
                  <Checkbox
                    checked={
                      column.linked_columns?.length
                        ? false
                        : column.allow_multiple
                    }
                    onChange={(e) =>
                      handleColumnChanged(e.target.checked, 'allow_multiple')
                    }
                    disabled={column.linked_columns?.length > 0}
                    style={{
                      fontSize: '10px',
                      margin: 0,
                    }}
                  >
                    Multiselect
                  </Checkbox>
                )}
              </InnerContainer>
            </Container>
          </ColContainer>
        );
      }}
    </Draggable>
  );
};

export default SeedColumnForm;
