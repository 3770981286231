import {
  // cell types' registering function
  AutocompleteCellType,
  CheckboxCellType,
  DateCellType,
  DropdownCellType,
  HandsontableCellType,
  NumericCellType,
  SelectCellType,
  TextCellType,
  registerCellType,
} from 'handsontable/cellTypes';
import 'handsontable/dist/handsontable.full.min.css';
import {DropdownEditor} from 'handsontable/editors/dropdownEditor';
import {
  // plugins' registering function
  AutoColumnSize,
  AutoRowSize,
  Autofill,
  BindRowsWithHeaders,
  ColumnSorting,
  ContextMenu,
  CopyPaste,
  DropdownMenu,
  ExportFile,
  Filters,
  HiddenColumns,
  HiddenRows,
  ManualColumnFreeze,
  ManualColumnMove,
  ManualColumnResize,
  ManualRowMove,
  ManualRowResize,
  MultiColumnSorting,
  PersistentState,
  Search,
  TrimRows,
  UndoRedo,
  registerPlugin,
} from 'handsontable/plugins';
import {range} from 'lodash';
import {statuses} from 'utils/consts';
import {stringToFloat, stripTags} from 'utils/helpers';

// register only the necessary plugins to reduce bundle size
registerPlugin(AutoColumnSize);
registerPlugin(AutoRowSize);
registerPlugin(Autofill);
registerPlugin(BindRowsWithHeaders);
registerPlugin(ColumnSorting);
registerPlugin(ContextMenu);
registerPlugin(CopyPaste);
registerPlugin(DropdownMenu);
registerPlugin(ExportFile);
registerPlugin(Filters);
registerPlugin(HiddenColumns);
registerPlugin(HiddenRows);
registerPlugin(ManualColumnFreeze);
registerPlugin(ManualColumnMove);
registerPlugin(ManualColumnResize);
registerPlugin(ManualRowMove);
registerPlugin(ManualRowResize);
registerPlugin(MultiColumnSorting);
registerPlugin(PersistentState);
registerPlugin(Search);
registerPlugin(TrimRows);
registerPlugin(UndoRedo);

// register the custom cell types
registerCellType(AutocompleteCellType);
registerCellType(CheckboxCellType);
registerCellType(DateCellType);
registerCellType(DropdownCellType);
registerCellType(HandsontableCellType);
registerCellType(NumericCellType);
registerCellType(SelectCellType);
registerCellType(TextCellType);

export const settings = {
  allowCreateEmpty: true,
  allowInsertColumn: false,
  allowInsertRow: true,
  allowRemoveColumn: false,
  allowRemoveRow: true,
  autofill: true,
  autoColumnSize: false,
  // autoRowSize: true,
  autoWrapRow: true,
  autoWrapCol: true,
  columnSorting: true,
  contextMenu: {
    items: {
      duplicate_row: {
        name: 'Duplicate row',
        callback: function (key, options) {
          const rows = range(options[0].start.row, options[0].end.row + 1);
          this.alter('insert_row_below', options[0].end.row, rows.length);
          const changes = [];
          rows.forEach((row) => {
            const data = this.getDataAtRow(row);
            data.forEach((cellData, i) => {
              // if col is ___original, skip
              if (this.colToProp(i).includes('___original') || i === 0) return;
              changes.push([row + rows.length, i, cellData]);
            });
          });
          this.setDataAtCell(changes);
        },
      },
      row_above: {},
      row_below: {},
      remove_row: {},
      undo: {},
      redo: {},
      copy: {},
    },
  },
  copyable: true,
  copyPaste: true,
  dateFormat: 'YYYY-MM-DD',
  dropdownMenu: {
    items: {
      filter_by_condition: {
        hidden() {
          return this.getSelectedRangeLast().to.col === 0;
        },
      },
      filter_operators: {
        hidden() {
          return this.getSelectedRangeLast().to.col === 0;
        },
      },
      filter_by_condition2: {
        hidden() {
          return this.getSelectedRangeLast().to.col === 0;
        },
      },
      filter_by_value: {
        hidden() {
          return this.getSelectedRangeLast().to.col === 0;
        },
      },
      filter_action_bar: {
        hidden() {
          return this.getSelectedRangeLast().to.col === 0;
        },
      },
    },
  },
  filters: true,
  licenseKey: 'non-commercial-and-evaluation',
  rowHeaders: true,
  manualColumnFreeze: true,
  manualColumnMove: false,
  manualColumnResize: true,
  manualRowFreeze: true,
  manualRowMove: true,
  manualRowResize: true,
  // minSpareRows: 1,
  persistentState: true,
  // preventOverflow: 'horizontal',
  renderAllColumns: true,
  // renderAllRows: true,
  search: true,
  sortIndicator: true,
  startCols: 5,
  startRows: 5,
  trimDropdown: false,
  trimWhitespace: true,
  height: '75vh',
};

export const columnTypes = [
  {value: 'text', label: 'Text'},
  {value: 'numeric', label: 'Numeric'},
  {value: 'date', label: 'Date'},
  {value: 'dropdown', label: 'Options'},
  {value: 'dropdown_numeric', label: 'Options (numeric)'},
  {value: 'autocomplete', label: 'Autocomplete'},
  // {value: 'regex', label: 'Regex'},
];

export const createIcon = (icon, tooltip) => {
  const ICON = document.createElement('SPAN');
  ICON.style.marginRight = '4px';
  ICON.style.fontSize = '12px';
  ICON.className = 'material-symbols-outlined';
  ICON.innerText = statuses[icon].icon;
  ICON.title = tooltip || statuses[icon].label;
  ICON.style.color = statuses[icon].color;
  ICON.style.cursor = 'help';
  return ICON;
};

export function safeHTMLRenderer(
  instance,
  TD,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  if (!instance) return TD;
  // empty the cell
  TD.innerHTML = '';

  const originalValue = instance.getDataAtRowProp(row, `${prop}___original`);
  let data = stripTags(value) || '';
  let originalData = stripTags(originalValue) || '';
  if (cellProperties?.data_type === 'INTEGER') {
    data = stringToFloat(value);
    originalData = stringToFloat(originalData);
  }
  const originalCol = instance.propToCol(`${prop}___original`);

  const metaData = instance.getCellMeta(row, col);
  if (!isNaN(originalCol) && originalData !== data) {
    const ORIG_TEXT = document.createElement('DIV');
    ORIG_TEXT.className = 'strikethrough';
    ORIG_TEXT.innerText = originalData;
    TD.appendChild(ORIG_TEXT);
    TD.className = 'diff-modify-cell';
  }
  const NEW_TEXT = document.createElement('DIV');
  NEW_TEXT.display = 'flex';
  NEW_TEXT.innerText = data;
  if (
    cellProperties?.type === 'dropdown' ||
    cellProperties?.type === 'autocomplete'
  ) {
    if (!cellProperties.readOnly) {
      TD.classList.add('htAutocomplete');
      const ARROW = document.createElement('SPAN');
      ARROW.classList = 'htAutocompleteArrow material-symbols-outlined';
      ARROW.style.cursor = 'pointer';
      ARROW.style.fontSize = '24px';
      ARROW.textContent = 'arrow_drop_down';
      NEW_TEXT.style.paddingRight = '4px';
      NEW_TEXT.appendChild(ARROW);
      TD.style.cursor = 'pointer';
      // ARROW.onclick = () => {
      //   const editor = instance.getCellEditor(row, col);
      //   editor.open();
      // };
    }
  }
  TD.appendChild(NEW_TEXT);
  if (metaData.removed_col) {
    TD.className = 'diff-removed-cell';
    return TD;
  }
  // if (metaData?.invalid) {
  //   NEW_TEXT.prepend(createIcon('invalid', metaData.invalid));
  // }
  // if cell is readOnly, dim the cell
  if (cellProperties.readOnly) {
    TD.style.backgroundImage =
      'linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))';
  }
  return TD;
}

export function multiSelectRenderer(
  instance,
  TD,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  if (!instance) return TD;

  TD.innerHTML = '';

  let data = instance.getDataAtRowProp(row, prop)?.toString().split(';') || [];
  let originalData =
    instance
      .getDataAtRowProp(row, `${prop}___original`)
      ?.toString()
      .split(';') || [];
  const allData = [...new Set([...data, ...originalData])].sort();

  if (cellProperties?.data_type === 'INTEGER') {
    data = data.map(stringToFloat).filter(Boolean);
    originalData = originalData.map(stringToFloat).filter(Boolean);
  }

  allData.forEach((item) => {
    if (!item) return;

    const TAG = document.createElement('SPAN');
    const ICON = document.createElement('SPAN');
    const TEXT = document.createElement('SPAN');
    TEXT.innerText = item;
    TAG.appendChild(TEXT);
    TAG.style.margin = '2px';

    if (!cellProperties.colOptions?.includes(item)) {
      TAG.prepend(createIcon('invalid'));
    }
    TAG.className = 'ant-tag';
    ICON.appendChild(document.createTextNode(String.fromCharCode(8855)));
    ICON.style.marginLeft = '5px';
    ICON.style.fontSize = '16px';
    ICON.className = 'htAutocompleteArrow';
    if (data.includes(item) && !originalData.includes(item)) {
      TAG.className = 'ant-tag ant-tag-green';
    } else if (!data.includes(item) && originalData.includes(item)) {
      TAG.className = 'ant-tag ant-tag-red';
      TEXT.style.textDecoration = 'line-through';
      ICON.innerHTML = '';
    }
    ICON.onclick = () => cellProperties.onClickTag(item, row, prop);
    TD.appendChild(TAG);
    TAG.appendChild(ICON);
  });

  TD.classList.add('htAutocomplete');
  const PLUS = document.createElement('DIV');
  PLUS.className = 'htAutocompleteArrow';
  PLUS.appendChild(document.createTextNode(String.fromCharCode(8853)));
  PLUS.style.marginTop = '4px';
  PLUS.style.fontSize = '16px';
  TD.appendChild(PLUS);
  const metaData = instance.getCellMeta(row, col);
  if (metaData.removed_col) {
    TD.className = 'diff-removed-cell';
  } else {
    TD.style.cursor = 'pointer';
  }
  return TD;
}

export function iconRenderer(
  instance,
  TD,
  row,
  col,
  prop,
  value,
  cellProperties
) {
  if (!instance) return TD;

  TD.innerHTML = '';

  if (cellProperties.removed) {
    TD.appendChild(createIcon('removed', cellProperties.removed));
  }
  if (cellProperties.duplicate) {
    TD.appendChild(createIcon('duplicate', cellProperties.duplicate));
  }
  if (cellProperties.edited) {
    TD.appendChild(createIcon('edited', cellProperties.edited));
  }
  if (cellProperties.invalid) {
    TD.appendChild(createIcon('invalid', cellProperties.invalid));
  }
  if (cellProperties.blank) {
    TD.appendChild(createIcon('blank', cellProperties.blank));
  }
  if (cellProperties.newRow) {
    TD.appendChild(createIcon('newRow', cellProperties.newRow));
  }
  // if cell is readOnly, dim the cell
  if (cellProperties.readOnly) {
    TD.style.backgroundImage =
      'linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05))';
  }
  return TD;
}

export class CustomAutocompleteEditor extends DropdownEditor {
  prepare(row, col, prop, td, value, cellProperties) {
    super.prepare(row, col, prop, td, value, cellProperties);
    if (!this.hot) return;
    this.cellMeta = this.hot.getCellMeta(row, col);
    this.linkedCols = this.cellMeta.linkedCols;
    this.colIdx = this.linkedCols?.findIndex((col) => col.name === prop);
  }

  finishEditing(restoreOriginalValue, ctrlDown, callback) {
    // Goofy fix to a weird problem:
    // When the filters menu is open on a linked autocomplete column that is editable,
    // any click on the menu will trigger the finishEditing method for the first row
    if (!this._opened) return;
    let changes = [];
    if (restoreOriginalValue !== false) {
      return super.finishEditing(restoreOriginalValue, ctrlDown, callback);
    } else if (this.cellMeta.allow_multiple) {
      const originalData = this.originalValue?.toString().split(';') || [];
      originalData.push(this.TEXTAREA.value);
      const newValue = [...new Set(originalData)]
        .sort()
        .filter(Boolean)
        .join(';');
      changes = [[this.row, this.col, newValue]];
    } else if (this.linkedCols?.length) {
      // get the 'aria-selected = true' option from the dropdown
      const selectedOption = this.TEXTAREA_PARENT.querySelector(
        'td[aria-selected="true"]'
      )?.textContent;
      // split the value by ' | ' and set the values for all the linked columns
      const val = selectedOption?.split(' | ');
      // if the value is empty, set ''
      changes = this.linkedCols.map((col, idx) => {
        const colIdx = this.hot.propToCol(col.name);
        return [this.row, colIdx, val?.[idx] ?? ''];
      });
    } else {
      changes = [[this.row, this.col, this.TEXTAREA.value]];
    }
    for (let i = 0; i < changes.length; i++) {
      if (!changes[i][2]) {
        changes[i][2] = '';
      } else if (this.cellMeta?.data_type === 'INTEGER') {
        changes[i][2] = stringToFloat(changes[i][2]);
      } else if (this.cellMeta?.data_type === 'STRING') {
        changes[i][2] = changes[i][2].toString();
      }
    }
    if (changes.length) {
      this.hot.setDataAtCell(changes);
    }
    super.finishEditing(restoreOriginalValue, ctrlDown, callback);
  }
}

export function multiselectValidator(value, callback) {
  let values = value?.toString()?.split(';') || [];
  // check the type of the options
  if (this.data_type === 'INTEGER') {
    try {
      values = values.map(stringToFloat);
    } catch {
      return callback(false);
    }
  }
  callback(values.some((val) => !this.colOptions?.includes(val)));
}

export function dropdownValidator(value, callback) {
  if (!value) return callback(true);
  let valid = this.colOptions?.includes(value);
  if (this.data_type === 'INTEGER') {
    try {
      valid = this.colOptions?.includes(stringToFloat(value));
    } catch {
      valid = false;
    }
  }
  callback(!!valid);
}

export function textValidator(value, callback) {
  if (!value) return callback(true);
  if (this.data_type === 'INTEGER') {
    try {
      callback(true);
    } catch {
      callback(false);
    }
  } else {
    callback(true);
  }
}

export function uniqueValidator(value, callback) {
  const data = this.instance.getDataAtCol(this.col);
  callback(data.filter((val) => val === value).length <= 1);
}

// combine the unique and dropdown validators
export function uniqueDropdownValidator(value, callback) {
  dropdownValidator.call(this, value, (valid) => {
    if (valid) {
      uniqueValidator.call(this, value, callback);
    } else {
      callback(false);
    }
  });
}
