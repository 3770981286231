import Fuse from 'fuse.js';
import Highlighter from 'react-highlight-words';

/**
 * Create a flexible filtering utility for nested data structures
 * @param {Object} config - Configuration for filtering
 * @returns {Object} Filtering methods and utilities
 */
export const createNestedDataFilter = (config = {}) => {
  const {
    // Default configuration options
    fuseOptions = {
      keys: ['title'],
      ignoreLocation: true,
      includeMatches: true,
      includeScore: true,
      threshold: 0.4,
      getFn: (obj) => {
        return obj.toString();
      },
    },
    tagMatchStrategy = 'every', // 'every' or 'some'
    debug = false,
  } = config;

  /**
   * Flatten the tree for efficient searching
   * @param {Array} nodes - Nested data nodes
   * @returns {Array} Flattened list of nodes
   */
  const flattenTree = (nodes = [], parentId) => {
    return nodes.reduce((acc, node) => {
      const newNode = {
        ...node,
        parent: parentId,
      };
      acc.push(newNode);
      if (node.children) {
        acc.push(...flattenTree(node.children, node.id));
      }
      return acc;
    }, []);
  };

  /**
   * Create Fuse search index
   * @param {Array} data - Data to index
   * @returns {Fuse} Fuse search instance
   */
  const createFuseIndex = (data, searchTerm) => {
    const flatData = flattenTree(data);
    return new Fuse(flatData, {
      ...fuseOptions,
      minMatchCharLength: Math.min(3, searchTerm.length),
    });
  };

  /**
   * Perform fuzzy search on nested data
   * @param {Array} data - Nested data to search
   * @param {string} searchTerm - Search query
   * @returns {Array} Filtered and restructured data
   */
  const fuzzySearch = (data, searchTerm) => {
    const fuse = createFuseIndex(data, searchTerm);
    const searchArray = searchTerm.split(' ').filter((i) => !!i);
    const searchResults = searchTerm
      ? fuse.search({
          $and: searchArray.map((term) => ({title: term})),
        })
      : data.map((item) => ({
          id: item.id,
          item,
          matches: [],
          score: 0,
        }));

    // Create a set of matched and ancestor IDs
    const matchedIds = new Set(searchResults.map((result) => result.item.id));

    /**
     * Recursively filter and rebuild tree structure
     * @param {Array} nodes - Current level of nodes
     * @returns {Array} Filtered nodes
     */
    const filterRecursively = (nodes) => {
      return nodes.reduce((filtered, node) => {
        // Direct match or has matched children
        const hasMatchedChildren = node.children
          ? filterRecursively(node.children).length > 0
          : false;

        const isDirectMatch = matchedIds.has(node.id);

        if (isDirectMatch) {
          // add the node and all its descendants
          filtered.push({
            ...node,
            title: (
              <Highlighter
                highlightStyle={{
                  backgroundColor: 'var(--primary-color)',
                  padding: 0,
                }}
                searchWords={searchArray}
                autoEscape={true}
                textToHighlight={node.title || ''}
              />
            ),
          });
        } else if (hasMatchedChildren) {
          // add the node and its matched children
          const filteredNode = {
            ...node,
            title: node.title,
          };

          if (node.children) {
            filteredNode.children = filterRecursively(node.children);
          }

          filtered.push(filteredNode);
        }

        return filtered;
      }, []);
    };

    return filterRecursively(data);
  };

  /**
   * Default tag matching strategy
   * @param {Object} item - Data item
   * @param {Array} filterTags - Tags to match against
   * @returns {boolean} Whether item matches tags
   */
  function defaultTagMatcher(item, filterTags) {
    // Support different matching strategies
    const matchMethod = tagMatchStrategy === 'some' ? 'some' : 'every';

    return filterTags[matchMethod]((tag) => {
      // Handle special cases like 'draft' or 'unpublished'
      if (tag.id === 'draft') {
        return !item.has_published_version;
      } else if (tag.id === 'data_changed') {
        return item.is_edited && !item.published;
      } else if (tag.id === 'schema_changed') {
        return item.has_published_version && !item.published;
      } else if (tag.id === 'search_context') {
        return item.is_search_context;
      } else if (tag.id === 'spotlight') {
        return item.is_spotlight;
      }

      // Standard tag matching
      return item.tags?.includes(tag.id);
    });
  }

  /**
   * Filter by tags with flexible matching strategy
   * @param {Array} data - Nested data to filter
   * @param {Array} tags - Tags to filter by
   * @param {Function} customTagMatcher - Optional custom tag matching function
   * @returns {Array} Filtered and restructured data
   */
  const filterByTags = (data, tags, customTagMatcher = defaultTagMatcher) => {
    if (!data || data.length === 0) return [];

    /**
     * Recursively filter nodes by tags
     * @param {Array} nodes - Nodes to filter
     * @returns {Array} Filtered nodes
     */
    const filterTagsRecursively = (nodes) => {
      return nodes.reduce((filtered, node) => {
        // Clone the node to avoid mutations
        const filteredNode = {...node};

        // Leaf node (page/item) tag matching
        if (node.type === 'leaf' || !node.children) {
          if (customTagMatcher(node, tags)) {
            filtered.push(filteredNode);
          }
        }
        // Node with potential children
        else {
          // Recursively filter children
          const filteredChildren = filterTagsRecursively(node.children);

          // Include node if it has matching children
          if (filteredChildren.length > 0) {
            filteredNode.children = filteredChildren;
            filtered.push(filteredNode);
          }
        }

        return filtered;
      }, []);
    };

    return filterTagsRecursively(data);
  };

  const pruneTreeNodes = (tree) => {
    // Base cases
    if (!tree) return null;
    if (tree.type !== 'node')
      return {
        ...tree,
        value: tree.slug,
      };

    // Recursively prune children
    const prunedChildren = tree.children
      ?.map((child) => pruneTreeNodes(child))
      .filter((child) => child !== null);

    // If no children remain after pruning, or no children existed, remove this node
    if (!prunedChildren || prunedChildren.length === 0) {
      return null;
    }

    // Return node with pruned children
    return {
      ...tree,
      children: prunedChildren,
      selectable: false,
      value: tree.type + tree.id,
    };
  };

  /**
   * Combine search and tag filtering
   * @param {Array} data - Nested data to filter
   * @param {string} searchTerm - Search query
   * @param {Array} tags - Tags to filter by
   * @returns {Array} Filtered and restructured data
   */
  const combineFilters = (data, searchTerm, tags) => {
    if (debug) {
      console.log('Initial data:', data);
      console.log('Search term:', searchTerm);
      console.log('Tags:', tags);
    }

    const searchFiltered = fuzzySearch(data, searchTerm);
    const tagFiltered = filterByTags(searchFiltered, tags);

    if (debug) {
      console.log('Final filtered data:', tagFiltered);
    }

    const pruned = pruneTreeNodes({
      children: tagFiltered,
      type: 'node',
    });

    return pruned?.children;
  };

  /**
   * Get active keys from filtered data
   * @param {Array} data - Filtered data
   * @returns {Array} Active keys
   */
  const getActiveKeys = (data) => {
    return flattenTree(data).map((item) => item.type + item.id);
  };

  /**
   * Get pruned initial data
   * @param {Array} data - Initial data
   * @returns {Array} Pruned data
   */
  const getInitialData = (data) => {
    return pruneTreeNodes({
      children: data,
      type: 'node',
    })?.children;
  };

  return {
    combineFilters,
    createFuseIndex,
    filterByTags,
    flattenTree,
    fuzzySearch,
    getActiveKeys,
    getInitialData,
  };
};
