import {emptySplitApi} from 'api/apiSlice';
import {uniqBy} from 'lodash';
import {handleRTKQError} from 'utils/errorHandler';

const seedsSlice = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSeeds: builder.query({
      query: (version) => `/seeds/seed_tree?version=${version}`,
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'Seeds', id: arg}],
    }),
    fetchSeedData: builder.query({
      query: ({name, version}) => `/seeds/seed/${name}/?version=${version}`,
      transformResponse: (response) => {
        // sort columns by index
        const sortedColumns = response.metadata?.columns?.sort(
          (a, b) => a.index - b.index
        );
        return {
          ...response,
          metadata: {
            ...response.metadata,
            columns: sortedColumns,
          },
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [
        {type: 'SeedData', id: `${arg.name}_${result.metadata.version}`},
      ],
    }),
    fetchColumnOptions: builder.query({
      query: (data) => `/seeds/column_options/?${data}`,
      transformResponse: (response) => {
        const deduped = {};
        // deduplicate stringified options for each source table
        for (const [key, value] of Object.entries(response.options)) {
          deduped[key] = uniqBy(value, JSON.stringify);
        }
        return {
          ...deduped,
          errors: response.errors,
        };
      },
      transformErrorResponse: handleRTKQError,
      providesTags: (result, error, arg) => [{type: 'ColumnOptions', id: arg}],
    }),
    setSeedData: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['SeedData', 'Seeds'],
    }),
    setSeedMetaData: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/${data.seed_name}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['SeedData', 'Seeds'],
    }),
    deleteSeedVersion: builder.mutation({
      query: (data) => ({
        url: `/seeds/seed/${data.seed_name}/?version=${data.version}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SeedData', 'Seeds'],
    }),
  }),
});

export const {
  useFetchSeedsQuery,
  useFetchSeedDataQuery,
  useFetchColumnOptionsQuery,
  useSetSeedDataMutation,
  useSetSeedMetaDataMutation,
  useDeleteSeedVersionMutation,
} = seedsSlice;
