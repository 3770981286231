import {HotTable} from '@handsontable/react';
import {Alert, Spin, message} from 'antd';
import {
  useFetchColumnOptionsQuery,
  useFetchSeedDataQuery,
} from 'api/seedsSlice';
import {
  CustomAutocompleteEditor,
  columnTypes,
  dropdownValidator,
  iconRenderer,
  multiSelectRenderer,
  multiselectValidator,
  safeHTMLRenderer,
  settings,
  textValidator,
} from 'components/seeds/HandsonSettings';
import TableButtons from 'components/seeds/TableButtons';
import Fuse from 'fuse.js';
import _, {isEqual} from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  selectIsFormEdited,
  updateFormField,
  updateOriginalField,
} from 'store/formSlice';
import {handleApiError} from 'utils/errorHandler';
import {generateUniqueId, stringToFloat} from 'utils/helpers';

// statuses:
// - removed: the row is empty
// - duplicate: the row has duplicate primary keys or unique columns
// - edited: the row has been edited
// - invalid: the row has invalid cells (list of invalid cells)
// - blank: the row has empty cells (list of empty cells)
// - newRow: the row is new

const TableEditor = ({seedId}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const isDirty = useSelector((state) =>
    selectIsFormEdited(state, `source_manager_${seedId}`)
  );

  const [allTableColumns, setAllTableColumns] = useState([]);
  const [autocompleteCols, setAutocompleteCols] = useState('');
  const [loading, setLoading] = useState(false);
  const [primaryKeyCols, setPrimaryKeyCols] = useState([]);
  const [isDiff, setIsDiff] = useState(false); // if the draft data is different from the final data
  const [initialData, setInitialData] = useState([]);

  const {data: seedData, isLoading: loadingData} = useFetchSeedDataQuery(
    {
      name: seedId,
      version: params.get('version') || 'latest',
    },
    {
      skip: !seedId,
    }
  );
  const {data: autocompleteOptions, isLoading: loadingOptions} =
    useFetchColumnOptionsQuery(autocompleteCols, {
      skip: autocompleteCols === '',
    });

  const hotRef = useRef(null);

  useEffect(() => {
    if (Object.keys(autocompleteOptions?.errors ?? {}).length) {
      handleApiError({
        status: 199,
        data: {detail: autocompleteOptions.errors},
      });
    }
  }, [autocompleteOptions]);

  useEffect(() => {
    if (!initialData.length) return;
    const draftData = getDraftData();
    dispatch(
      updateOriginalField({
        id: `source_manager_${seedId}`,
        field: 'table_data',
        value: _.cloneDeep(draftData),
      })
    );
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: 'table_data',
        value: _.cloneDeep(draftData),
      })
    );
  }, [initialData]);

  useEffect(() => {
    /*
     * Set up the columns for the table
     * If the seed data is not available, return
     * If the seed data has a message, display the message and return
     * If the seed data has no columns, return
     */
    if (!seedData || !seedId) return;
    let cols = seedData?.metadata?.columns?.length
      ? [...seedData?.metadata.columns]
      : [];

    const tempCols = [
      {name: '', type: 'text', key: 'status'},
      {name: '___primaryKey', type: 'text', key: 'primaryKey'},
    ];
    cols.forEach((col) => {
      const baseCol = {
        ...col,
        linkedCols: col.linked_columns?.length
          ? [
              {source_column: col.source_column, name: col.name},
              ...col.linked_columns,
            ]
          : undefined,
      };
      tempCols.push(baseCol);
      tempCols.push({
        ...col,
        name: `${col.name}___original`,
        is_unique: false,
      });

      if (col.type === 'autocomplete' && col.linked_columns?.length) {
        const colData = {
          ...col,
          linkedCols: [
            {source_column: col.source_column, name: col.name},
            ...col.linked_columns,
          ],
        };
        col.linked_columns.forEach((linkedCol) => {
          tempCols.push({
            ...colData,
            ...linkedCol,
            is_unique: false,
          });
          tempCols.push({
            ...colData,
            ...linkedCol,
            name: `${linkedCol.name}___original`,
            is_unique: false,
          });
        });
      }
    });

    if (seedData?.data?.length) {
      Object.keys(seedData.data[0]).forEach((key) => {
        if (!tempCols.find((col) => col.name === key)) {
          const newCol = {
            name: key,
            type: 'text',
            key,
            col_id: generateUniqueId([], 'int'),
            linked_columns: [],
            removed_col: true,
          };
          tempCols.push(newCol);
          tempCols.push({...newCol, name: `${key}___original`});
        }
      });
    }

    const tableCols = tempCols.map(getColOptions);
    setAllTableColumns(tableCols);
  }, [seedData, autocompleteOptions]);

  useEffect(() => {
    /*
     * Set up the autocomplete options for the table
     * If the columns are not available, return
     * If the columns have no autocomplete columns, return
     * If the columns have no source_table or source_column, return
     * If the columns have no linked columns, return
     */
    const params = new URLSearchParams();
    allTableColumns.forEach((col) => {
      if (
        col.type === 'autocomplete' &&
        col.source_table &&
        col.source_column
      ) {
        const sourceCols = params.get(col.source_table)?.split(',') || [];
        if (!sourceCols.includes(col.source_column)) {
          sourceCols.push(col.source_column);
        }
        col.linkedCols?.forEach((linkedCol) => {
          if (!sourceCols.includes(linkedCol.source_column)) {
            sourceCols.push(linkedCol.source_column);
          }
        });
        params.set(col.source_table, sourceCols.join(','));
      }
    });
    setAutocompleteCols(params.toString());
  }, [allTableColumns]);

  useEffect(() => {
    /*
     * Set up the table with the seed data
     * If the seed data is not available, return
     * If the seed data has a message, display the message and return
     */
    if (!seedData || !allTableColumns) return;
    if (seedData.message) {
      message.error({
        content: seedData.message,
        duration: 10,
        key: 'dataSourceError',
      });
      return;
    }
    const hot = hotRef?.current?.hotInstance;
    if (!hot) return;
    hot.updateSettings({
      data: initialData,
      colHeaders: allTableColumns?.map((col) => col.title),
      columns: allTableColumns,
      hiddenColumns: {
        columns: getHiddenColumns(),
        copyPasteEnabled: false,
      },
      afterChange: onAfterChange,
      afterGetColHeader: onAfterGetColHeader,
      // afterSetDataAtCell: onAfterChange,
      beforeAutofill: onBeforeAutofill,
      beforeChange: onBeforeChange,
      beforePaste: onBeforePaste,
      beforeRemoveRow: onBeforeRemoveRow,
    });
  }, [allTableColumns, initialData]);

  useEffect(() => {
    // If data and draft_data are different, set isDiff to true
    if (!seedData) return;
    const data = seedData.data;
    const draftData = seedData.draft_data;
    if (!data || !draftData) return;
    setIsDiff(!isEqual(data, draftData));
  }, [seedData]);

  const onBeforePaste = (data, coords) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // if the column is read only, don't allow pasting - display an error message
    if (
      coords[0].startCol === 0 ||
      allTableColumns[coords[0].startCol].is_read_only
    ) {
      message.error({
        content: 'Cannot paste to read only columns',
        duration: 2,
      });
      return false;
    }
    // first, set the table to loading so the user knows the data is being pasted
    setLoading(true);

    // we only want to paste to visible columns, so we need to match the columns to the data
    const visibleCols = [];
    let index = coords[0].startCol;
    // get the columns to paste to
    while (
      visibleCols.length < data[0].length &&
      index < allTableColumns.length
    ) {
      const col = allTableColumns[index];
      if (col.name && !col.name.includes('___')) {
        visibleCols.push({
          ...col,
          index,
        });
      }
      index++;
    }
    // we want columns with data_type INTEGER to be converted to numbers
    const dataToPaste = data
      .map(
        (row) =>
          row.map((val, i) => {
            const colDataType = visibleCols[i]?.data_type;
            return colDataType === 'INTEGER' ? stringToFloat(val) : val;
          })
        // if the row has more columns than the visible columns, remove the extra columns
      )
      .map((row) => row.slice(0, visibleCols.length));

    // if the data is only one row, we want to paste the same data to all the destination rows
    // if the data is multiple rows, we want to paste the data to the corresponding rows
    const newData = [];
    if (dataToPaste.length === 1) {
      const destRows = Array.from(
        {length: coords[0].endRow - coords[0].startRow + 1},
        (v, k) => k + coords[0].startRow
      );
      destRows.forEach((row) => {
        dataToPaste[0].forEach((val, i) => {
          newData.push([row, visibleCols[i].index, val]);
        });
      });
    } else {
      const destRows = Array.from(
        {length: data.length},
        (v, k) => k + coords[0].startRow
      );
      dataToPaste.forEach((row, i) => {
        row.forEach((val, j) => {
          newData.push([destRows[i], visibleCols[j]?.index, val]);
        });
      });
    }
    runActions([
      {
        method: 'setDataAtCell',
        data: newData,
      },
    ]);

    // set the table to not loading
    setLoading(false);
    return false;
  };

  const onBeforeAutofill = (
    selectionData,
    sourceRange,
    targetRange,
    direction
  ) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    setLoading(true);

    const autofillSourceCols = [];
    for (let i = sourceRange.from.col; i <= sourceRange.to.col; i++) {
      const colToProp = hot.colToProp(i);
      const col = allTableColumns.find((c) => c.name === colToProp);
      if (!col.name.includes('___')) {
        autofillSourceCols.push(col);
      }
    }
    // check if any of the cells in the source range are linked columns
    const autofillCols = [];
    for (const col of autofillSourceCols) {
      if (col.linkedCols?.length) {
        col.linkedCols.forEach((linkedCol) => {
          const col = allTableColumns.find((c) => c.name === linkedCol.name);
          if (col && autofillCols.indexOf(col) === -1) autofillCols.push(col);
        });
      } else {
        autofillCols.push(col);
      }
    }
    const rowsToUpdate = Array.from(
      {length: targetRange.to.row - targetRange.from.row + 1},
      (v, k) => k + targetRange.from.row
    );
    const dataToUpdate = [];
    for (const col of autofillCols) {
      // get the data from the source column
      const sourceColIdx = hot.propToCol(col.name);
      const sourceCellData = hot.getDataAtCell(
        sourceRange.from.row,
        sourceColIdx
      );
      // for each row in the target range, add an entry to the dataToUpdate array
      rowsToUpdate.forEach((row) => {
        dataToUpdate.push([row, sourceColIdx, sourceCellData]);
      });
    }
    runActions([
      {
        method: 'setDataAtCell',
        data: dataToUpdate,
      },
    ]);
    setLoading(false);
  };

  const handleClickTag = (item, row, prop) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // prevent the editor from opening
    hot.selectCell(row, hot.propToCol(prop));
    // get the old data and add the new item to it
    const oldData =
      hot.getDataAtRowProp(row, prop)?.toString().split(';').filter(Boolean) ||
      [];
    const data = oldData.includes(item)
      ? oldData.filter((val) => val !== item)
      : [...oldData, item];

    const col = hot.propToCol(prop);
    try {
      hot.setDataAtCell(row, col, data.join(';'));
    } catch (error) {
      console.error(error);
    }
    onAfterChange([[row, prop, oldData.join(';'), data.join(';')]], 'edit');
  };

  const addPrimaryKeys = (data, cols, keyName) => {
    return data.map((row) => {
      const newRow = allTableColumns.reduce((acc, col) => {
        acc[col.name] = row[col.name] ?? '';
        return acc;
      }, {});
      const uniqueKey =
        cols
          .map((col) => row[col] ?? '')
          .join('___')
          .replace(/_+/g, '') || undefined;
      newRow[keyName] = uniqueKey;
      return newRow;
    });
  };

  const getPrimaryKeyCols = (primaryKey = [], useLinkedCols = true) => {
    const cols = [];
    for (const col of primaryKey) {
      const linkedCols = allTableColumns.find(
        (c) => c.name === col
      )?.linkedCols;
      if (linkedCols?.length && useLinkedCols) {
        linkedCols.forEach((c) => c.name && cols.push(c.name));
      } else {
        cols.push(col);
      }
    }
    return cols;
  };

  useEffect(() => {
    /*
     * Create a new table with the original data and the draft data
     * If the seed data is not available, return an empty array
     * Add the primary key to the data
     */
    if (!seedData) return;
    const cols = getPrimaryKeyCols(seedData?.metadata?.primary_key, false);
    setPrimaryKeyCols(cols);

    const draftData = seedData.draft_data;

    const draftDataWithPrimaryKeys = addPrimaryKeys(
      draftData,
      cols,
      '___primaryKey'
    );
    const originalData = addPrimaryKeys(
      [...(seedData.data ?? [])],
      cols,
      '___primaryKey'
    );

    const uniquePrimaryKeys = [
      ...new Set(
        draftDataWithPrimaryKeys
          .map((row) => row.___primaryKey)
          .concat(originalData.map((row) => row.___primaryKey))
      ),
    ];

    const newTableData = [];
    for (const uniqueKey of uniquePrimaryKeys) {
      const originalRow = originalData.filter(
        (row) => row.___primaryKey === uniqueKey
      );
      const draftRow = draftDataWithPrimaryKeys.filter(
        (row) => row.___primaryKey === uniqueKey
      );

      /*
       * create a new row with the original data and the draft data
       * if there is more than one row with the same primary key, add all the rows, unless the entire row is empty
       * numeric values are converted to numbers
       */
      for (let i = 0; i < Math.max(originalRow.length, draftRow.length); i++) {
        const newRow = {};
        for (const col of allTableColumns) {
          if (col.name === '') continue;
          if (col.name.includes('___original')) {
            const dataCol = col.name.split('___')[0];
            newRow[col.name] = originalRow[i]?.[dataCol] ?? '';
          } else {
            newRow[col.name] = draftRow[i]?.[col.name] ?? '';
          }
          if (col.data_type === 'INTEGER') {
            newRow[col.name] = stringToFloat(newRow[col.name]);
          } else if (col.data_type === 'STRING') {
            newRow[col.name] = newRow[col.name]?.toString();
          }
        }
        if (Object.values(newRow).join('') !== '') {
          newTableData.push(newRow);
        }
      }
    }
    setInitialData(newTableData);
  }, [allTableColumns, seedData]);

  const getColOptions = useCallback(
    (col) => {
      const options = {
        ...col,
        data: col.name,
        title: col.title ?? col.name,
        allowEmpty: true,
        allowInvalid: true,
        renderer: safeHTMLRenderer,
        validator: textValidator,
        width: 200,
      };
      if (col.key === 'status') {
        options.renderer = iconRenderer;
        options.editor = false;
        options.readOnly = true;
        options.width = 55;
      } else if (col.type === 'dropdown') {
        options.strict = true;
        options.renderer = col.allow_multiple
          ? multiSelectRenderer
          : safeHTMLRenderer;
        options.validator = col.allow_multiple
          ? multiselectValidator
          : dropdownValidator;
        options.source = !col.source
          ? []
          : typeof col.source === 'string'
            ? JSON.parse(col?.source.replace(/'/g, '"') ?? '[]')
            : col.source;
        options.colOptions = options.source;
        options.onClickTag = handleClickTag;
      } else if (col.type === 'autocomplete') {
        options.strict = true;
        options.renderer = safeHTMLRenderer;
        options.editor = CustomAutocompleteEditor;
        options.validator = dropdownValidator;
        if (col.source_table) {
          const keys = col.linkedCols?.map((c) => c.source_column);
          options.allOptions = [
            ...new Set(
              // concatenate all the linked columns and remove duplicates
              autocompleteOptions?.[col.source_table]
                ?.map((row) =>
                  keys?.length
                    ? keys
                        .reduce((acc, key) => `${acc}${row[key] ?? ''} | `, '')
                        .slice(0, -3)
                    : row[col.source_column]
                )
                ?.sort()
                ?.filter((val) => val)
            ),
          ];
          options.colOptions = [
            ...new Set(
              autocompleteOptions?.[col.source_table]
                ?.map((row) => row[col.source_column])
                ?.sort()
                ?.filter((val) => val)
            ),
          ];
          if (col.data_type === 'INTEGER') {
            options.colOptions = options.colOptions.map((val) =>
              stringToFloat(val)
            );
          }
          options.source = function (query, process) {
            if (!query) {
              const matches = options.allOptions.slice(0, 100);
              matches.unshift('');
              return process(matches);
            }

            // const queriesArray = query.toString().split(' ');
            // const minMatchCharLength = queriesArray.reduce(
            //   (acc, q) => (q.length < acc ? q.length : acc),
            //   1
            // );
            const fuse = new Fuse(options.allOptions, {
              keys: keys ?? [col.source_column],
              includeMatches: true,
              includeScore: true,
              threshold: 0.4,
              // ignoreLocation: true,
              // minMatchCharLength: minMatchCharLength,
              useExtendedSearch: true,
              // shouldSort: false,
              getFn: (obj) => {
                return obj.toString();
              },
            });

            const results = fuse.search(query);
            const matches = results.map((row) => row.item).slice(0, 100);
            matches.unshift('');
            return process(matches);
          };
        } else {
          options.source = [];
          options.colOptions = [];
        }
      }
      if (col.removed_col || col.is_read_only) {
        options.editor = false;
        options.readOnly = true;
      }
      return options;
    },
    [autocompleteOptions]
  );

  const getHiddenColumns = useCallback(() => {
    return allTableColumns
      ?.map((col, i) => ({...col, index: i}))
      ?.filter((col) => col.name.indexOf('___') > -1)
      ?.map((col) => col.index);
  }, [allTableColumns]);

  const onAfterGetColHeader = (col, TH) => {
    // TODO: this is being called multiple times, need to figure out why
    const colData = allTableColumns[col];
    if (!colData || colData.name.includes('___')) return;

    const title = colData.removed_col
      ? 'Removed'
      : colData.type === 'dropdown'
        ? `${colData.allow_multiple ? 'Multiselect ' : ''}Options: \n${colData.source.join('\n')}`
        : colData.type === 'autocomplete'
          ? `Source: \n${colData.source_table}.${colData.source_column}${colData.allow_multiple ? '\nMultiselect' : ''}`
          : columnTypes.find((type) => type.value === colData.type)?.label;

    TH.setAttribute('title', title);
    if (colData?.removed_col) {
      TH.className = 'diff-removed-cell';
    }
    if (col === 0) {
      const button = TH.querySelector('.changeType');
      if (button) {
        button.style.display = 'none';
      }
    }
    return TH;
  };

  const onBeforeRemoveRow = (index, amount, physicalRows, source) => {
    // instead of removing the row, remove the data from visible columns
    // if the row is new, (e.g., has no original data) remove the entire row
    // NOTE: I'm allowing the user to remove all visible values, including read only columns, since I assume there will be use cases where the user wants a row to be removed entirely
    if (source === undefined) return;
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    const allActions = [];
    const rowsToRemove = [];

    for (let row of physicalRows) {
      const data = hot.getDataAtRow(row);
      if (data.every((val) => !val)) {
        rowsToRemove.push(row);
      } else {
        for (let j = 0; j < hot.countCols(); j++) {
          const prop = hot.colToProp(j);
          if (prop.includes('___') || j === 0) continue;
          allActions.push([row, prop, '']);
        }
      }
    }
    // if all the rows in the table are removed, keep the first one
    if (hot.countRows() === rowsToRemove.length) {
      rowsToRemove.pop();
    }
    runActions([
      {
        method: 'setSourceDataAtCell',
        data: allActions,
      },
      ...rowsToRemove.map((row) => ({
        method: 'removeRow',
        row,
      })),
    ]);
    return false;
  };

  const onBeforeChange = (changes, source) => {
    if (!changes) return;
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;
    // if the data_type is INTEGER, convert the value to a number
    changes.forEach((change) => {
      const [row, prop, oldValue, newValue] = change;
      const col = hot.propToCol(prop);
      const cellMeta = hot.getCellMeta(row, col);

      // cancel changes to hidden columns
      if (cellMeta?.name.includes('___')) {
        change[3] = oldValue;
      } else if (cellMeta.data_type === 'INTEGER') {
        change[3] = stringToFloat(newValue);
      } else {
        change[3] = newValue?.toString().trim() ?? '';
      }
    });
  };

  const runActions = (allActions) => {
    const hot = hotRef.current?.hotInstance;
    if (!hot || !allActions?.length) return;

    // consolidate all setDataAtCell actions into one
    const setDataAtCellActions = allActions.filter(
      (action) => action.method === 'setDataAtCell'
    );
    const otherActions = allActions.filter(
      (action) => action.method !== 'setDataAtCell'
    );

    const allSetDataActions = [];
    // if data that is part of the primary key is changed, recalculate the primary key
    const rowsToRecalculatePrimaryKey = new Set();
    for (const action of setDataAtCellActions) {
      if (Array.isArray(action.data)) {
        action.data.forEach((data) => {
          const [row, col, value] = data;
          const prop = hot.colToProp(col);
          if (primaryKeyCols.includes(prop)) {
            rowsToRecalculatePrimaryKey.add(row);
          }
          allSetDataActions.push([row, col, value]);
        });
      } else {
        const {row, col, value} = action;
        allSetDataActions.push([row, col, value]);
        const prop = hot.colToProp(col);
        if (primaryKeyCols.includes(prop)) {
          rowsToRecalculatePrimaryKey.add(row);
        }
      }
    }
    if (allSetDataActions.length) {
      otherActions.push({
        method: 'setDataAtCell',
        data: allSetDataActions,
      });
    }

    hot.batch(() => {
      otherActions.forEach((action) => {
        switch (action.method) {
          case 'validateCell':
            hot.validateCell(action.data, action.cellMeta, (valid) => {});
            break;
          case 'setCellMeta':
            hot.setCellMeta(action.row, action.col, action.key, action.value);
            break;
          case 'setDataAtCell':
            if (Array.isArray(action.data)) {
              hot.setDataAtCell(action.data);
            } else {
              hot.setDataAtCell(action.row, action.col, action.value);
            }
            break;
          case 'setSourceDataAtCell':
            if (Array.isArray(action.data)) {
              hot.setSourceDataAtCell(action.data);
            } else {
              hot.setSourceDataAtCell(action.row, action.col, action.value);
            }
            break;
          case 'removeRow':
            hot.alter('remove_row', action.row);
          default:
            break;
        }
      });
      hot.render();
    });
    const newPrimaryKeys = [];
    const primaryKeyColIdx = hot.propToCol('___primaryKey');
    rowsToRecalculatePrimaryKey.forEach((row) => {
      let newPrimaryKey = primaryKeyCols
        .map((col) => hot.getDataAtCell(row, hot.propToCol(col)))
        .join('___');
      if (newPrimaryKey.replaceAll('_', '') === '') {
        newPrimaryKey = undefined;
      }
      newPrimaryKeys.push([row, primaryKeyColIdx, newPrimaryKey]);
    });
    hot.setDataAtCell(newPrimaryKeys);
  };

  const getDraftData = () => {
    const hot = hotRef.current?.hotInstance;
    if (!hot) return;

    const data = hot.getSourceData();
    const currentData = data.map((row) => {
      const newRow = {};
      for (const col of allTableColumns) {
        if (!col.name || col.name.includes('___')) continue;
        newRow[col.name] = row[col.name];
      }
      return newRow;
    });
    return currentData;
  };

  const onAfterChange = (changes, source) => {
    /*
     * Check if the data has been edited, and if so, set isDirty to true
     * We need to compare the new data with initialData
     */
    if (source === 'updateData') return;

    const draftData = getDraftData();
    dispatch(
      updateFormField({
        id: `source_manager_${seedId}`,
        field: 'table_data',
        value: _.cloneDeep(draftData),
      })
    );
  };

  return (
    <div>
      <Alert
        description={
          <div>
            <p>How to use this page:</p>
            <ul>
              <li>Double click or start typing to open a cell dropdown.</li>
              {seedData?.metadata?.enable_autoload && (
                <li>
                  Click <b>Load New Entries</b> to load any new entries from the
                  source table. Entries that already appear in the table will
                  not be duplicated or overwritten.
                </li>
              )}
              <li>
                Deleting data:
                <ul>
                  <li>
                    Selecting a row/rows and hitting <b>Delete</b> will remove
                    the data from editable columns only.
                  </li>
                  <li>
                    Selecting a row/rows, right clicking, and selecting{' '}
                    <b>Remove Row/s</b> will remove the entire row.
                  </li>
                </ul>
              </li>
              <li>
                Click <b>Check Statuses</b> to review changes before saving.
              </li>
              <li>
                Hit <b>Save Draft</b> before clicking <b>Finalize Changes</b>.
              </li>
            </ul>
          </div>
        }
        type="success"
        style={{marginBottom: '20px'}}
      />
      <Spin spinning={loadingData || loadingOptions || loading}>
        <TableButtons
          addPrimaryKeys={addPrimaryKeys}
          allTableColumns={allTableColumns}
          autocompleteOptions={autocompleteOptions}
          getPrimaryKeyCols={getPrimaryKeyCols}
          hotRef={hotRef}
          isDiff={isDiff}
          isDirty={isDirty}
          loading={loadingData || loadingOptions || loading}
          primaryKeyCols={primaryKeyCols}
          runActions={runActions}
          setLoading={setLoading}
        />
        <div style={{height: '75vh', marginTop: '5px'}}>
          <HotTable ref={hotRef} settings={settings} />
        </div>
      </Spin>
    </div>
  );
};

export default TableEditor;
