import {Card, Tag} from 'antd';
import {useFetchTagsQuery} from 'api/tagsSlice';
import React from 'react';
import {Link} from 'react-router-dom';
import {setContrast} from 'utils/helpers';

const CatalogItem = ({item, editorMode, onChangeFilterTags, path}) => {
  const {data: tags} = useFetchTagsQuery();

  return (
    <Link
      // className="site-collapse-panel-item"
      to={`/${path}/${item.slug ?? item.title}/${editorMode ? 'editor' : ''}`}
    >
      <Card
        bordered={false}
        style={{
          backgroundColor: '#f9f9f9',
          margin: '2px 0',
        }}
        bodyStyle={{padding: 12}}
      >
        <h4>
          {item.title}
          {editorMode && !item.has_published_version && (
            <Tag
              color="red"
              style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
              onClick={() => onChangeFilterTags(['draft'])}
              key="draft"
            >
              Draft
            </Tag>
          )}
          {editorMode && item.has_published_version && !item.published && (
            <Tag
              color="orange"
              style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
              onClick={() => onChangeFilterTags(['schema_changed'])}
              key="schema_changed"
            >
              Unpublished Changes
            </Tag>
          )}
          {editorMode && item.is_edited && (
            <Tag
              color="magenta"
              style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
              onClick={() => onChangeFilterTags(['data_changed'])}
              key="data_changed"
            >
              Unpublished Data Changes
            </Tag>
          )}
          {editorMode && item.is_search_context && (
            <Tag
              color="blue"
              style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
              onClick={() => onChangeFilterTags(['search_context'])}
              key="search_context"
            >
              Search Context
            </Tag>
          )}
          {editorMode && item.is_spotlight && (
            <Tag
              color="green"
              style={{marginRight: 3, marginLeft: 5, cursor: 'pointer'}}
              onClick={() => onChangeFilterTags(['spotlight'])}
              key="spotlight"
            >
              Spotlight
            </Tag>
          )}
          {item.tags?.map((tagId) => {
            const tag = tags?.find((t) => t.id === tagId);
            if (!tag) return null;

            const textColor = setContrast(tag.color);
            return (
              <Tag
                color={tag.color}
                style={{
                  marginRight: 1,
                  marginLeft: 5,
                  color: textColor,
                  cursor: 'pointer',
                  borderColor:
                    textColor === '#000000d9' ? '#d9d9d9' : tag.color,
                }}
                onClick={() => onChangeFilterTags([tag.id])}
                key={tag.name}
              >
                {tag.name}
              </Tag>
            );
          })}
        </h4>
        <h5>{item.short_description}</h5>
      </Card>
    </Link>
  );
};

export default CatalogItem;
