import {
  CloseCircleOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import {Alert, Button, Tooltip} from 'antd';
import InputField from 'components/genericComponents/Input';
import SelectOptions from 'components/genericComponents/SelectOptions';
import React, {useMemo} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {validMetricsForColumnType, validTypesForFilter} from 'utils/consts';

const Container = styled.div`
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 10px;
  align-items: start;
  &:hover {
    border-color: #aaa;
  }
  &:focus {
    border-color: #1890ff;
  }
`;

const SortButton = ({column, setColumn}) => {
  const sortOptions = ['ascend', 'descend', undefined];

  const onClick = (e) => {
    e.stopPropagation();
    const idx = sortOptions.indexOf(column?.sort);
    const newSort = sortOptions[(idx + 1) % sortOptions.length];
    setColumn(newSort, 'sort');
  };

  return (
    // cycle between sort options (ascend, descend, undefined), and set sort_priority
    <>
      <Tooltip
        title={
          !column.sort
            ? 'Sort'
            : column.sort === 'ascend'
              ? 'Sort Descending'
              : 'Remove Sort'
        }
      >
        <Button
          onClick={onClick}
          size="large"
          style={{
            border: column.sort
              ? '1px solid var(--primary-color)'
              : '1px solid #ddd',
          }}
        >
          {column.sort === 'ascend' ? (
            <SortAscendingOutlined style={{color: 'var(--primary-color)'}} />
          ) : column.sort === 'descend' ? (
            <SortDescendingOutlined style={{color: 'var(--primary-color)'}} />
          ) : (
            <span style={{color: 'rgba(0, 0, 0, 0.25)'}}>&#8645;</span>
          )}
        </Button>
      </Tooltip>
    </>
  );
};

const ColumnForm = ({column, columns, handleDelete, idx, setColumn}) => {
  const parsedColumns = useMemo(() => {
    return columns
      .map((column) => ({
        value: column.column_name,
        label: `${column.column_name} (${column.data_type})`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [columns]);

  const metricOptions = useMemo(() => {
    return Object.keys(validMetricsForColumnType).map((option) => {
      return {
        value: option,
        label: option,
        disabled: !validMetricsForColumnType[option].includes(
          columns.find((col) => col.column_name === column.source_column)
            ?.data_type
        ),
      };
    });
  }, [columns, column.source_column]);

  const filterOptions = useMemo(() => {
    return Object.keys(validTypesForFilter).map((option) => {
      return {
        value: option,
        label: option,
        disabled: !validTypesForFilter[option].includes(
          columns.find((col) => col.column_name === column.source_column)
            ?.data_type
        ),
      };
    });
  }, [columns, column.source_column]);

  const handleColumnChanged = (value, field) => {
    const updatedColumn = {
      ...column,
      [field]: value,
    };
    // if (updatedColumn.column_type === 'METRIC') {
    //   return {
    //     ...updatedColumn,
    //     ...updateMetric(field, updatedColumn),
    //   };
    // } else if (field === 'source_column') {
    //   return {
    //     ...updatedColumn,
    //     ...updateColumnSelected(value, updatedColumn),
    //   };
    // }

    // if (field === 'metric_type' || field === 'source_column') {
    //   updatedColumn.alias = autogenerateMetricColumnName(updatedColumn);
    // }
    setColumn(updatedColumn);
  };

  const updateColumnSelected = (value, changedElement) => {
    if (!value) return changedElement;

    return {
      ...changedElement,
      source_column: value,
      data_type: columns.find((column) => column.column_name === value)
        ?.data_type,
      alias: value,
    };
  };

  const autogenerateMetricColumnName = (metric) => {
    const c = metric.source_column?.toLowerCase().replace(' ', '_') ?? '';
    const m = metric.metric_type?.toLowerCase().replace(' ', '_') ?? '';
    return `${c}_${m}`;
  };

  return (
    <Draggable key={column.id} draggableId={`column-${column.id}`} index={idx}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className="flex-row"
        >
          <Tooltip title="Remove">
            <CloseCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            />
          </Tooltip>
          <div className="flex-column">
            <div className="flex-row">
              <InputField
                label="Name"
                onChange={(e) => handleColumnChanged(e.target.value, 'alias')}
                required={true}
                style={{width: '150px'}}
                value={column.alias ?? column.source_column}
              />
              <SelectOptions
                label="Source Column"
                onChange={(value) =>
                  handleColumnChanged(value, 'source_column')
                }
                options={parsedColumns}
                required={true}
                style={{width: '190px'}}
                value={column.source_column}
              />
              <SelectOptions
                label="Filter Type"
                onChange={(value) => handleColumnChanged(value, 'filter_type')}
                options={filterOptions}
                required={true}
                style={{width: '130px'}}
                value={column.filter_type}
              />
            </div>
            {column.column_type !== 'FILTER' && (
              <div className="flex-row">
                <SelectOptions
                  label="Column Type"
                  onChange={(value) =>
                    handleColumnChanged(value, 'column_type')
                  }
                  options={['DIMENSION', 'METRIC']}
                  required={true}
                  style={{width: '150px'}}
                  value={column.column_type}
                />
                {column.column_type === 'METRIC' && (
                  <SelectOptions
                    label="Metric"
                    onChange={(value) =>
                      handleColumnChanged(value, 'metric_type')
                    }
                    options={metricOptions}
                    required={true}
                    style={{width: '190px'}}
                    value={column.metric_type}
                  />
                )}
                {column.column_type === 'DIMENSION' && (
                  <SelectOptions
                    label="Link Text Column"
                    onChange={(value) =>
                      handleColumnChanged(value, 'link_text')
                    }
                    options={parsedColumns}
                    style={{width: '190px'}}
                    value={column.link_text}
                  />
                )}
                <span style={{flex: 1}} />
                <SortButton column={column} setColumn={handleColumnChanged} />
                <Tooltip title="Freeze column">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleColumnChanged(!column.freeze, 'freeze');
                    }}
                    size="large"
                    style={{
                      border: column.freeze
                        ? '1px solid var(--primary-color)'
                        : '1px solid #ddd',
                      color: column.freeze
                        ? 'var(--primary-color)'
                        : 'rgba(0, 0, 0, 0.25)',
                      fontWeight: 600,
                      fontSize: '1.2em',
                    }}
                  >
                    &#10052;
                  </Button>
                </Tooltip>
              </div>
            )}
            {column.errors?.length > 0 && (
              <Alert
                message={
                  <div>
                    {column.errors?.map((error) => (
                      <div key={error}>* {error}</div>
                    ))}
                  </div>
                }
                type="error"
              />
            )}
          </div>
        </Container>
      )}
    </Draggable>
  );
};

export default ColumnForm;
